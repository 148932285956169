import Link from "next/link"
import { useRouter } from "next/router"

export const LoginForm = () => {
  const router = useRouter()
  const next = router.query.next ? decodeURIComponent(router.query.next as string) : "/"
  return (
    <div className="flex items-center justify-center h-screen max-w-xs mx-auto text-center">
      <div className="flex flex-col items-center space-y-8">
        <span className="inline-flex items-center self-center space-x-2">
          <img className="w-4 h-4" src="/logos/diagram-bw-logo.svg" />
          <h1 className="font-bold text-md">designOS</h1>
        </span>
        <p className="mt-8 text-lg font-bold">A private space for your design team</p>
        <div className="flex space-x-2">
          <Link passHref href="https://airtable.com/shrFFFHfensxPNoTS">
            <div className="px-3 py-2 text-sm font-bold leading-4 bg-white border rounded-full shadow-sm cursor-pointer border-neutral-300 hover:bg-neutral-50">
              Request access
            </div>
          </Link>
          <Link href={`/api/auth/google?redirectUrl=${next}`} passHref>
            <div className="px-3 py-2 text-sm font-bold leading-4 bg-white border rounded-full shadow-sm cursor-pointer border-neutral-300 hover:bg-neutral-50">
              Sign in
            </div>
          </Link>
        </div>
      </div>

      {/* <h1 className="text-lg font-bold">Login</h1>
      <span className="satoshi">
        Welcome to <strong>designOS</strong>
      </span>
      <Button className="flex items-center space-x-2">
        <Link href="/api/auth/google" passHref>
          <>
            <img className="w-4 h-4" src="https://logo.clearbit.com/google.com" />
            <a href="/api/auth/google"> Sign in with Google</a>
          </>
        </Link>
      </Button> */}
    </div>
  )
}

export default LoginForm
