import { colors, ColorScheme, extendTheme, VechaiProvider } from "@vechaiui/react"
import LoginForm from "app/auth/components/LoginForm"
import "app/core/styles/index.css"
import { withBlitz } from "app/blitz-client"
import { useRouter } from "next/router"
import { useQueryErrorResetBoundary } from "@blitzjs/rpc"
import { AppProps, ErrorBoundary, ErrorFallbackProps } from "@blitzjs/next"
import { Suspense } from "react"
import { Toaster } from "react-hot-toast"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
// import analytics from "app/utils/analytics"
import { GoogleAnalytics, usePagesViews } from "nextjs-google-analytics"
import ErrorComponent from "app/components/ErrorComponent"
import { AuthenticationError, AuthorizationError } from "blitz"

const black = {
  "50": "#E6E6E6",
  "100": "#CCCCCC",
  "200": "#999999",
  "300": "#666666",
  "400": "#333333",
  "500": "#000000",
  "600": "#000000",
  "700": "#000000",
  "800": "#000000",
  "900": "#000000",
}

const fullBlack = {
  "50": "#000000",
  "100": "#000000",
  "200": "#000000",
  "300": "#000000",
  "400": "#333333",
  "500": "#000000",
  "600": "#000000",
  "700": "#000000",
  "800": "#000000",
  "900": "#000000",
}

const red = {
  DEFAULT: "#EF4444",
  "50": "#F7A2A2",
  "100": "#F58F8F",
  "200": "#F26A6A",
  "300": "#EF4444",
  "400": "#E71414",
  "500": "#B30F0F",
  "600": "#800B0B",
  "700": "#4C0707",
  "800": "#180202",
  "900": "#000000",
}
const gray = {
  "50": "#FAFAFA",
  "100": "#F7F7F7",
  "200": "#F0F0F0",
  "300": "#E8E8E8",
  "400": "#E0E0E0",
  "500": "#D8D8D8",
  "600": "#ADADAD",
  "700": "#828282",
  "800": "#575757",
  "900": "#2B2B2B",
}

const light: ColorScheme = {
  id: "light",
  type: "light",
  colors: {
    bg: {
      base: "#f8f8f8",
      fill: colors.gray["600"],
    },
    text: {
      foreground: colors.gray["900"],
      muted: colors.gray["900"],
    },
    primary: black,
    secondary: gray,
    neutral: colors.gray,
    error: {
      foreground: colors.red["900"],
      muted: colors.red["100"],
    },
  },
}

export const bee: ColorScheme = {
  id: "light",
  type: "light",
  colors: {
    bg: {
      base: "#fafafa",
      fill: colors.warmGray["100"],
    },
    text: {
      foreground: colors.warmGray["900"],
      muted: colors.warmGray["700"],
    },
    primary: colors.amber,
    neutral: colors.warmGray,
  },
}

const theme = extendTheme({
  cursor: "pointer",
  colorSchemes: {
    light,
  },
})

const App = ({ Component, pageProps }: AppProps & { err: any }) => {
  const getLayout = Component.getLayout || ((page) => page)
  usePagesViews()
  // TODO: Figure out how to use React Error Boundary and Suspense and surround the component that does this error handling

  return (
    //@ts-ignore
    <Suspense fallback={<span />}>
      <ErrorBoundary
        FallbackComponent={RootErrorFallback}
        onReset={useQueryErrorResetBoundary().reset}
      >
        <VechaiProvider theme={theme} colorScheme="light">
          <Toaster position="top-right" />
          {getLayout(
            <>
              <GoogleAnalytics />
              {/* @ts-ignore */}
              <Component {...pageProps} />
            </>
          )}
          <ReactQueryDevtools initialIsOpen={false} />
        </VechaiProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  const router = useRouter()
  if (error instanceof AuthenticationError) {
    // push to errorPage is there is an auth error
    const authError = (router.query.authError as string) || null
    if (authError) {
      router.push(`/error?authError=${authError}`)
    }
    return (
      <VechaiProvider theme={theme} colorScheme="light">
        {/*@ts-ignore*/}
        <LoginForm onSuccess={resetErrorBoundary} />
      </VechaiProvider>
    )
  } else if (error instanceof AuthorizationError) {
    return (
      <VechaiProvider theme={theme} colorScheme="light">
        <ErrorComponent
          statusCode={error.statusCode}
          title="Sorry, you are not authorized to access this"
        />
      </VechaiProvider>
    )
  } else {
    return (
      <VechaiProvider>
        <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
      </VechaiProvider>
    )
  }
}

export default withBlitz(App)
