import Link from "next/link";
import { NextSeo } from "next-seo"

type ErrorComponentProps = {
  statusCode: number
  title: string
}
const ErrorComponent = ({ statusCode, title }: ErrorComponentProps) => {
  return (
    <>
      <NextSeo title={`${statusCode} | ${title}`} description={title} />
      <main className="flex items-center justify-center h-screen max-w-xs mx-auto text-center">
        <div className="flex flex-col items-center space-y-8">
          <span className="inline-flex items-center self-center space-x-2">
            <img className="w-4 h-4" src="/logos/diagram-bw-logo.svg" />
            <h1 className="font-bold text-md">designOS | {statusCode}</h1>
          </span>
          <p className="mt-8 text-lg font-bold">{title}</p>
          <div className="flex space-x-2">
            <Link href={`/`} passHref>
              <div className="px-3 py-2 text-sm font-bold leading-4 bg-white border rounded-full shadow-sm cursor-pointer border-neutral-300 hover:bg-neutral-50">
                Home
              </div>
            </Link>
          </div>
        </div>
      </main>
    </>
  )
}

export default ErrorComponent
